import { FC } from 'react'
import { getTokenDetails } from '../../lib/tokens'
import Link from 'next/link'
import { getCoinAnalysis } from '../../lib/router'

export type CoinProps = {
  id: string
}

const CoinCard: FC<CoinProps> = ({ id }) => {
  const details = getTokenDetails(id)

  if (!details) {
    return null
  }

  return (
    <>
      <div className="CoinCard">
        <Link href={getCoinAnalysis(id)}>
          <a>{details.name}</a>
        </Link>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .CoinCard {
          }
        `}
      </style>
    </>
  )
}

export default CoinCard
