import { FC, PropsWithChildren } from 'react'

const H2: FC<PropsWithChildren<{ id?: string }>> = ({ id, children }) => {
  return (
    <h2 id={id} className="text-xl text-white mb-3 mt-6">
      {children}
    </h2>
  )
}

export default H2
