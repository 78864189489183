import { FC, PropsWithChildren } from 'react'
import {
  TaggedArticle,
  TaggedArticleAttributes,
} from '../lib/get-newest-tagged-articles'
import ArticleCard from './ArticleCard'

const ArticleCards: FC<
  PropsWithChildren<{ articles: TaggedArticle[] | TaggedArticleAttributes[] }>
> = ({ articles }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
      {articles.map((a, k) => {
        return <ArticleCard key={k} article={a} />
      })}
    </div>
  )
}

export default ArticleCards
