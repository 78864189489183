import { FC, PropsWithChildren } from 'react'

const ArticleHeadline: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <h1 className="text-center mb-1 text-white text-xl lg:text-2xl">
      {children}
    </h1>
  )
}

export default ArticleHeadline
