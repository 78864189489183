import { getTokenDetails } from './tokens'

export function getCoinAnalysis(id: string) {
  const details = getTokenDetails(id)

  if (!details) {
    return ''
  }

  return `/daily-analysis/${details.slug}`
}
