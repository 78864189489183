import Image from 'next/image'
import { adClick } from '../lib/gtag'

const BinanceAd = () => {
  return (
    <a
      href="https://accounts.binance.us/en/register?ref=35131270"
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        adClick('binance_us')
      }}
    >
      <Image
        src="/referral/binance-referral.png"
        width="375"
        height="282"
        layout={'responsive'}
        alt="Earn commissions on trades."
      />
    </a>
  )
}

export default BinanceAd
