export type TokenDetails = {
  id: string
  name: string
  title?: string
  slug: string
  tag: string
  twitter?: string
  twitterCard?: string
  precision?: number
}

const tokens: TokenDetails[] = [
  {
    id: 'CATGIRL_USDT',
    name: 'Catgirl Coin',
    title: 'Catgirl Crypto Price Prediction',
    slug: 'catgirl-coin',
    tag: '#catgirlcoin #catgirl',
    twitter: 'catgirlcoin',
    twitterCard: '/twitter/catgirl-twitter-card.jpg',
  },
  {
    id: 'MATIC_USDT',
    name: 'Polygon (Matic)',
    slug: 'polygon-matic',
    tag: '#polygon',
    twitterCard: '/twitter/polygon-matic.jpeg',
  },
  {
    id: 'ETH_USDT',
    name: 'Ethereum',
    slug: 'ethereum',
    tag: '#Ethereum',
    twitter: 'ethereum',
  },
  {
    id: 'BTC_USDT',
    name: 'Bitcoin',
    slug: 'bitcoin',
    tag: '#btc',
  },
  {
    id: 'SHIB_USDT',
    name: 'Shiba Inu',
    slug: 'shiba-inu',
    tag: '#shib',
  },
  {
    id: 'DEFILAND_USDT',
    name: 'DeFi Land',
    slug: 'defi-land',
    tag: '#defiland',
  },
  {
    id: 'BNB_USDT',
    name: 'Binance Coin',
    slug: 'binance-coin',
    tag: '#bnb',
  },
  {
    id: 'SOL_USDT',
    name: 'Solana',
    slug: 'solana',
    tag: '#solana',
  },
  {
    id: 'ADA_USDT',
    name: 'Cardano',
    slug: 'cardano',
    tag: '#cardano',
  },
  {
    id: 'XRP_USDT',
    name: 'XRP',
    slug: 'xrp',
    tag: '#xrp',
  },
  {
    id: 'DOT_USDT',
    name: 'Polkadot',
    slug: 'polkadot',
    tag: '#polkadot',
  },
  {
    id: 'LUNA_USDT',
    name: 'Terra',
    slug: 'terra',
    tag: '$UST $LUNA',
    twitter: `terra_money`,
  },
  {
    id: 'DOGE_USDT',
    name: 'Dogecoin',
    slug: 'doge',
    tag: '#doge',
  },
  {
    id: 'AVAX_USDT',
    name: 'Avalanche',
    slug: 'Avalanche',
    tag: '#avalanche',
  },
  {
    id: 'CRO_USDT',
    name: 'Crypto.com Coin',
    slug: 'crypto-com-coin',
    tag: '#cro',
    twitter: 'cryptocom',
    precision: 5,
  },
  {
    id: 'LTC_USDT',
    name: 'Litecoin',
    slug: 'litecoin',
    tag: '#litecoin',
  },
  {
    id: 'UNI_USDT',
    name: 'Uniswap',
    slug: 'uniswap',
    tag: '#uniswap',
    twitter: 'Uniswap',
  },
  {
    id: 'ALGO_USDT',
    name: 'Algorand',
    slug: 'algorand',
    tag: '#algorand',
  },
  {
    id: 'TRX_USDT',
    name: 'TRON',
    slug: 'tron',
    tag: '#tron',
  },
  {
    id: 'LINK_USDT',
    name: 'Chainlink',
    slug: 'chainlink',
    tag: '#chainlink',
  },
  {
    id: 'BCH_USDT',
    name: 'Bitcoin Cash',
    slug: 'bitcoin-cash',
    tag: '#bch',
  },
  {
    id: 'XLM_USDT',
    name: 'Stellar',
    slug: 'stellar',
    tag: '#xlm',
  },
  {
    id: 'DAI_USDT',
    name: 'Dai',
    slug: 'dai',
    tag: '#dai',
  },
  {
    id: 'MANA_USDT',
    name: 'Decentraland (MANA)',
    slug: 'decentraland',
    tag: '#mana',
  },
  {
    id: 'AXS_USDT',
    name: 'Axie Infinity',
    slug: 'axie-infinity',
    tag: '#axieinfinity',
  },
  {
    id: 'FTT_USDT',
    name: 'FTX Token',
    slug: 'ftx-token',
    tag: '#ftxtoken',
  },
  {
    id: 'FIL_USDT',
    name: 'Filecoin',
    slug: 'filecoin',
    tag: '#filecoin',
  },
  {
    id: 'NEAR_USDT',
    name: 'NEAR Protocol',
    slug: 'near-protocol',
    tag: '#near',
  },
  {
    id: 'VET_USDT',
    name: 'VeChain',
    slug: 'vechain',
    tag: '#vechain',
  },
  {
    id: 'EGLD_USDT',
    name: 'Elrond',
    slug: 'elrond',
    tag: '#elrond',
  },
  {
    id: 'ATOM_USDT',
    name: 'Cosmos',
    slug: 'cosmos',
    tag: '#cosmos',
  },
  {
    id: 'ICP_USDT',
    name: 'Internet Computer',
    slug: 'internet-computer',
    tag: '#internetcomputer',
  },
  {
    id: 'ETC_USDT',
    name: 'Ethereum Classic',
    slug: 'ethereum-classic',
    tag: '#etc',
  },
  {
    id: 'SAND_USDT',
    name: 'The Sandbox',
    slug: 'the-sandbox',
    tag: '#sandbox',
  },
  {
    id: 'HBAR_USDT',
    name: 'Hedera',
    slug: 'hedera',
    tag: '$hbar #Hedera',
    twitter: 'hedera',
    precision: 5,
  },
  {
    id: 'THETA_USDT',
    name: 'THETA',
    slug: 'theta',
    tag: '#theta',
    twitter: 'Theta_Network',
    precision: 4,
  },
  {
    id: 'XTZ_USDT',
    name: 'Tezos',
    slug: 'tezos',
    tag: '#tezos',
  },
  {
    id: 'FTM_USDT',
    name: 'Fantom',
    slug: 'fantom',
    tag: '#fantom',
  },
  {
    id: 'XMR_USDT',
    name: 'Monero',
    slug: 'monero',
    tag: '#monero',
  },
  {
    id: 'KLAY_USDT',
    name: 'Klaytn',
    slug: 'klaytn',
    tag: '#klaytn',
  },
  {
    id: 'GALA_USDT',
    name: 'Gala',
    slug: 'gala',
    tag: '#gala',
  },
  {
    id: 'LRC_USDT',
    name: 'Loopring',
    slug: 'loopring',
    tag: '#loopring',
  },
  {
    id: 'BTT_USDT',
    name: 'BitTorrent',
    slug: 'bittorrent',
    tag: '#bittorrent',
    twitterCard: '/twitter/bittorrent.jpeg',
  },
  {
    id: 'IOTA_USDT',
    name: 'IOTA',
    slug: 'iota',
    tag: '#iota',
  },
  {
    id: 'GRT_USDT',
    name: 'The Graph',
    slug: 'the-graph',
    tag: '#thegraph',
  },
  {
    id: 'EOS_USDT',
    name: 'EOS',
    slug: 'eos',
    tag: '#eos',
  },
  {
    id: 'HNT_USDT',
    name: 'Helium',
    slug: 'helium',
    tag: '#helium',
  },
  {
    id: 'FLOW_USDT',
    name: 'Flow',
    slug: 'flow',
    tag: '#flow',
  },
  {
    id: 'CAKE_USDT',
    name: 'PancakeSwap',
    slug: 'CAKE',
    tag: 'cake',
  },
  {
    id: 'STX_USDT',
    name: 'Stacks',
    slug: 'stacks',
    tag: '#stacks',
  },
  {
    id: 'KSM_USDT',
    name: 'Kusama',
    slug: 'kusama',
    tag: '#kusama',
  },
  {
    id: 'MKR_USDT',
    name: 'Maker',
    slug: 'maker',
    tag: '#maker',
  },
  {
    id: 'AAVE_USDT',
    name: 'Aave',
    slug: 'aave',
    tag: '#aave',
  },
  {
    id: 'ZEC_USDT',
    name: 'Zcash',
    slug: 'zcash',
    tag: '#zcash',
  },
  {
    id: 'XEC_USDT',
    name: 'eCash',
    slug: 'ecash',
    tag: '#ecash $XEC',
    twitter: 'eCashOfficial',
    precision: 8,
  },
  {
    id: 'AMP_USDT',
    name: 'Amp',
    slug: 'amp',
    tag: '#amp',
  },
  {
    id: 'ENJ_USDT',
    name: 'Enjin Coin',
    slug: 'enjin-coin',
    tag: '#enjin',
  },
  {
    id: 'QNT_USDT',
    name: 'Quant',
    slug: 'QNT',
    tag: '#qnt',
  },
  {
    id: 'ONE_USDT',
    name: 'Harmony',
    slug: 'harmony',
    tag: '#harmony',
  },
  {
    id: 'BAT_USDT',
    name: 'Basic Attention Token',
    slug: 'basic-attention-token',
    tag: '#bat',
  },
]

export function getTokens(): TokenDetails[] {
  return tokens
}

export function getTokenDetails(id: string): TokenDetails | undefined {
  return tokens.find((t) => t.id.toLowerCase() === id.toLowerCase())
}

export function getTokenDetailsBySlug(slug: string): TokenDetails | undefined {
  return tokens.find((t) => t.slug.toLowerCase() === slug.toLowerCase())
}
