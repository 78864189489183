import type { NextPage } from 'next'
import Script from 'next/script'
import CoinCard from '../components/CoinCard'
import { NextSeo } from 'next-seo'
import ArticleHeadline from '../components/ArticleHeadline'
import P from '../components/p'
import H2 from '../components/H2'
import BinanceAd from '../components/BinanceAd'
import { TaggedArticle } from '../lib/get-newest-tagged-articles'
import { GetStaticProps } from 'next'
import getNewestArticles from '../lib/get-newest-articles'
import ArticleCards from '../components/ArticleCards'
import { CryptoPage } from '../lib/get-crypto-page'
import getCryptoPages from '../lib/get-crypto-pages'

type PageProps = {
  articles: TaggedArticle[]
  pages: CryptoPage[]
}

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const articles = await getNewestArticles()
  const pages = await getCryptoPages()

  return {
    props: {
      articles,
      pages,
    },
    revalidate: 60 * 60,
  }
}

const Home: NextPage<PageProps> = ({ articles, pages }) => {
  return (
    <>
      <NextSeo
        title="Crypto Analysis | Miro Knows Crypto"
        description="Miro Knows Crypto
            provides you non-biased pure math based analysis to help guide you
            on your trading journey."
        canonical="https://crypto.miroknows.com/"
      />
      <div className="grid gap-4 grid-cols-1 md:grid-cols-12">
        <div className="col-span-1 md:col-span-9">
          <ArticleHeadline>
            Let Math Light Your Cryptocurrency Journey
          </ArticleHeadline>
          <P>
            A statistical edge is beneficial when trading cryptocurrency.
            Surrounding yourself with statistical advantage increases your odds
            of making a profit. Do this is by using tools that help you find
            patterns in the market and predict trends. Miro Knows Crypto
            provides you non-biased pure math based analysis to help guide you
            on your trading journey.
          </P>
          <H2>Latest Articles</H2>
          <ArticleCards articles={articles} />
          <H2>Daily Cryptocurrency Analysis</H2>
          <div className="grid gap-y-4 mb-2 grid-cols-2 lg:grid-cols-4 md:gap-x-6">
            {pages.map((t) => (
              <CoinCard key={t.symbol} id={t.symbol} />
            ))}
          </div>
          <div id="amzn-assoc-ad-5a70f63a-d5b2-43ff-999e-2388789fde07" />
          <Script
            strategy={'lazyOnload'}
            src={`//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=5a70f63a-d5b2-43ff-999e-2388789fde07&rdate=${new Date().getTime()}`}
          />
        </div>
        <div className="col-span-1 md:col-span-3">
          <BinanceAd />
        </div>
      </div>
    </>
  )
}

export default Home
