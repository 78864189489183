import { FC, PropsWithChildren, useState } from 'react'
import {
  TaggedArticle,
  TaggedArticleAttributes,
} from '../lib/get-newest-tagged-articles'
import Image from 'next/image'
import Link from 'next/link'

const ArticleCard: FC<
  PropsWithChildren<{ article: TaggedArticle | TaggedArticleAttributes }>
> = ({ article }) => {
  const [attributes] = useState<TaggedArticleAttributes>(
    'attributes' in article ? article.attributes : article
  )

  return (
    <div className="rounded-lg shadow-lg bg-white">
      <Link href={`/articles/${attributes.slug}`}>
        <a>
          <div className="overflow-hidden">
            <div className="md:hidden rounded-t-lg overflow-hidden mb-2">
              <Image
                priority={true}
                layout="responsive"
                src={attributes.image1x1.data.attributes.url}
                width={attributes.image1x1.data.attributes.width}
                height={attributes.image1x1.data.attributes.height}
                alt={attributes.image1x1.data.attributes.alternativeText}
              />
            </div>
            <div className="hidden md:block rounded-t-lg overflow-hidden mb-2">
              <Image
                priority={true}
                layout="responsive"
                src={attributes.image16x9.data.attributes.url}
                width={attributes.image16x9.data.attributes.width}
                height={attributes.image16x9.data.attributes.height}
                alt={attributes.image16x9.data.attributes.alternativeText}
              />
            </div>

            <div className="block m-2">
              <p className="text-lg font-semibold text-gray-900">
                {attributes.headline}
              </p>
              <p className="mt-3 text-sm text-gray-500">
                {attributes.shortDescription}
              </p>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default ArticleCard
